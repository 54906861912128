<template>
  <v-row justify="center">
    <v-dialog :value="true" persistent max-width="600">
      <v-card class="pa-8">
        <v-row class="justify-end">
          <v-btn
            icon color="primary"
            @click="$emit('close-dialog-warning-modify')"
          >
            <v-icon v-text="'close'" />
          </v-btn>
        </v-row>
        <v-row>
          <v-icon x-large :color="'yellow'" v-text="'warning_outline'" />
        </v-row>
        <v-row>
          <v-card-text class="pl-0">
            <p v-text="$t('project.form.warningDialog.editForm')" />
            <dl class="pl-4">
              <dt class="font-weight-bold mb-1" v-text="$t('project.form.warningDialog.deleteComponentTitle')" />
              <dd class="mb-3" v-text="$t('project.form.warningDialog.deleteComponentDescription')" />
              <dt class="font-weight-bold mb-1" v-text="$t('project.form.warningDialog.addComponentTitle')" />
              <dd class="mb-3" v-text="$t('project.form.warningDialog.addComponentDescription')" />
              <dt class="mb-1" v-html="$t('project.form.warningDialog.editInformationTitle')" />
              <dd v-text="$t('project.form.warningDialog.editInformationDescription')" />
            </dl>
          </v-card-text>
        </v-row>
        <v-card-actions class="justify-space-around align-center">
          <v-btn
            color="primary" min-width="12rem"
            @click="$emit('confirm-modify')" v-text="$t('common.accept')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'WarningModifyDialog',
}
</script>
<style lang="scss" scoped>
dl dt {
  display: list-item;
  list-style-type: disc;
}
</style>
